/**
 * Entry point of application, where App is rendered within the div with the id of "app"
 */

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { render } from "react-dom";

import App from "./App";

render(<App></App>, document.getElementById("app"));
